<template>
  <layout-sub name="consultant">
    <b-row>
      <b-col v-for="(el, index) in list" :key="index" cols="12" xl="6">
        <base-horizontal-card
          :img="el.thumb"
          :title="el.title"
          :sub-title="el.c_title"
          :detail="el.content"
          class="p-consultant-card mb-5"
        />
      </b-col>
    </b-row>
  </layout-sub>
</template>

<script>
import LayoutSub from "@/components/LayoutSub";
import BaseHorizontalCard from "@/components/BaseHorizontalCard";
import { fatchCurrent } from "@/api";

export default {
  name: "Consultant",
  components: { LayoutSub, BaseHorizontalCard },
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    // 获取当前页面内容
    this.init();
  },
  methods: {
    async init() {
      const { data } = await fatchCurrent(33);
      if (data) {
        this.list = data;
      }
    },
  },
};
</script>

<style lang="scss">
.p-consultant {
  &-card {
    &.card {
      border: none;
    }

    @include media-breakpoint-up(sm) {
      .card-img {
        width: 400px;
        height: 400px;
      }
    }
    @include media-breakpoint-up(lg) {
      .card-img {
        width: 100%;
        height: auto;
      }
    }

    @include media-breakpoint-up(xl) {
      .card-text {
        font-size: $font-size-lg;
      }
    }
  }
}
</style>

<template>
  <b-card no-body :border-variant="borderVariant" class="overflow-hidden">
    <b-row no-gutters>
      <b-col v-if="!imgRight" lg="6">
        <div class="c-horizontal-card-img overflow-hidden">
          <b-card-img
            :src="img"
            :alt="title"
            class="rounded-0"
            style="position: relative; z-index: 1"
          />
        </div>
      </b-col>
      <b-col lg="6">
        <b-card-body
          :title="title"
          :sub-title="subTitle"
          :class="[!title ? 'pt-0' : '', imgRight && 'p-0 pr-lg-3']"
        >
          <b-card-text
            text-tag="div"
            :class="title ? 'mt-3 pt-3 border-top' : ''"
            v-html="detail"
          />
        </b-card-body>
      </b-col>
      <b-col v-if="imgRight" lg="6">
        <div>
          <b-card-img :src="img" :alt="title" class="rounded-0" />
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "BaseHorizontalCard",
  props: {
    img: { type: String, default: "" },
    title: { type: String, default: "" },
    subTitle: { type: String, default: "" },
    detail: { type: String, default: "" },
    borderVariant: { type: String, default: "" },
    imgRight: { type: Boolean, default: false },
  },
};
</script>
